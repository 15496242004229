import {
  BrowserModule,
  HammerModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppNavbarComponent } from './app-navbar/app-navbar.component';
import { AppSidebarComponent } from './app-sidebar/app-sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  API_HANDLER,
  ApiModule,
  BASE_PATH,
  VERSION,
  Configuration,
} from 'piwe-front-swagger-client';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { DragulaModule } from 'ng2-dragula';
import { CmsComponent } from './cms/cms.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { environment } from 'src/app/shared/environment';
import { SharedModule } from './shared/shared.module';
import { PixsellPreviewUrlPipe } from './pixsell-preview-url.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ApiHandlerService } from './api-handler.service';
import { Error404Component } from './error404/error404.component';
import { CacheInterceptor } from './cache.interceptor';

import { registerLocaleData } from '@angular/common';
import localeHr from '@angular/common/locales/hr';
import localeHrExtra from '@angular/common/locales/extra/hr';
import * as Sentry from '@sentry/angular';
import packageJson from '../../package.json';

import * as Hammer from 'hammerjs';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Error500Component } from './error500/error500.component';
import { NewsletterPopupComponent } from './newsletter-popup/newsletter-popup.component';
import { Router } from '@angular/router';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { NgxCaptchaModule } from 'ngx-captcha';
const version: string = packageJson.version;
const apiPackageVersion: string = packageJson.apiPackageVersion;
const openApiSpecVersion: string = packageJson.openApiSpecVersion;

registerLocaleData(localeHr, 'hr-HR', localeHrExtra);

export function getVersion(): string {
  return `piwe-front=${version};piwe-front-swagger-client=${apiPackageVersion};piwe-front-openapi=${openApiSpecVersion}`;
}

export function getApiUrl(): string {
  return environment.apiUrl;
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, undefined, '.v12-2024-05-07.json');
}

export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AppNavbarComponent,
    AppSidebarComponent,
    CmsComponent,
    PixsellPreviewUrlPipe,
    Error404Component,
    Error500Component,
    NewsletterPopupComponent,
  ],
  imports: [
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyB1hMsJp7uHrQUZwWaFcpGCXRsebdpxfvE',
      authDomain: 'piwe-web.firebaseapp.com',
      projectId: 'piwe-web',
      storageBucket: 'piwe-web.appspot.com',
      messagingSenderId: '969954407319',
      appId: '1:969954407319:web:7a4123a3c183aaabd4cad2',
      measurementId: 'G-W30K8BCMW4',
    }),
    AngularFireDatabaseModule,
    BrowserModule,
    NgxGoogleAnalyticsModule.forRoot('UA-5480887-1'),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    HammerModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    InfiniteScrollModule,
    ApiModule.forRoot(() => {
      return new Configuration();
    }),
    DragulaModule.forRoot(),
    NgxCaptchaModule
  ],
  providers: [
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    { provide: BASE_PATH, useFactory: getApiUrl },
    { provide: VERSION, useFactory: getVersion },
    { provide: API_HANDLER, useClass: ApiHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    { provide: LOCALE_ID, useValue: 'en' },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
