<div class="height"></div>
<header class="header">
  <div class="header__left">
    <img
      class="header__logo"
      style="cursor: pointer"
      [routerLink]="[currentLang]"
      src="/assets/logo.svg"
      alt="logo"
    />
    <div class="header__vr"></div>
    <nav>
      <ul class="header__nav" *ngIf="!isLoggedIn || (isLoggedIn && isLoaded)">
        <li>
          <a
            [href]="'/' + currentLang"
            [innerHTML]="'homepage_services.navbar.index' | translate"
          >
          </a>
        </li>
        <li>
          <div class="nav-dropdown">
            <span
              [innerHTML]="
                ('homepage_services.navbar.dropdown.title' | translate) + ' '
              "
            ></span>
            <img src="/assets/dropdown.svg" />
            <div class="nav-dropdown-menu">
              <a
                [routerLink]="'/' + currentLang + '/services/event'"
                [innerHTML]="
                  'homepage_services.navbar.dropdown.events' | translate
                "
              >
              </a>
              <a
                [routerLink]="'/' + currentLang + '/services/portrait'"
                [innerHTML]="
                  'homepage_services.navbar.dropdown.location' | translate
                "
              >
              </a>
              <a
                [routerLink]="'/' + currentLang + '/services/product'"
                [innerHTML]="
                  'homepage_services.navbar.dropdown.product' | translate
                "
              >
              </a>
              <a
                [routerLink]="'/' + currentLang + '/services/drone'"
                [innerHTML]="
                  'homepage_services.navbar.dropdown.drone' | translate
                "
              >
              </a>
              <a
                [routerLink]="'/' + currentLang + '/services/native'"
                [innerHTML]="
                  'homepage_services.navbar.dropdown.native' | translate
                "
              >
              </a>
              <a
                [routerLink]="'/' + currentLang + '/services/studio'"
                [innerHTML]="
                  'homepage_services.navbar.dropdown.studio' | translate
                "
              >
              </a>
            </div>
          </div>
        </li>
        <li>
          <a
            [routerLink]="'/' + currentLang + '/about-us'"
            [innerHTML]="'homepage_services.navbar.about' | translate"
          >
          </a>
        </li>
        <li>
          <a
            [routerLink]="'/' + currentLang + '/contact-us'"
            [innerHTML]="'homepage_services.navbar.contact' | translate"
          >
          </a>
        </li>
      </ul>
    </nav>
  </div>

  <div class="header__right">
    <a class="pixsell-full-link" href="https://www.pixsell.hr/"
       target="_blank"
       [innerHTML]="'homepage_services.navbar.see_pixsell' | translate"
      ></a
    >
    <button class="header__lang-btn" (click)="openLanguageModal()">
      <img
        src="{{
          currentLang == 'hr'
            ? '/assets/croatia-flag.png'
            : '/assets/english-flag.png'
        }}"
        alt=""
        class="header__flag"
      />
      <img
        src="/assets/dropdown.svg"
        alt=""
        [@openCloseIcon]="!isLanguageModalOpen ? 'closedIcon' : 'openIcon'"
      />
      <ul
        class="about-us__dropdown language-dropdown"
        *ngIf="isLanguageModalOpen"
      >
        <li (click)="redirectToChangeLanguage('hr', $event)">
          <img
            src="/assets/croatia-flag.png"
            alt=""
            class="header__flag-list"
          />
          <span [class.bold-currentLang]="currentLang == 'hr'">Hrvatski</span>
        </li>
        <li (click)="redirectToChangeLanguage('en', $event)">
          <img
            src="/assets/english-flag.png"
            alt=""
            class="header__flag-list"
          />
          <span [class.bold-currentLang]="currentLang == 'en'">English</span>
        </li>
      </ul>
    </button>
    <!-- <button class="header__about" (click)="openAboutUsMenu()">
      <div
        class="header__about-text"
        [@colorOrange]="!isAboutUsMenuOpen ? 'closedIcon' : 'openIcon'"
        [innerHTML]="'navbar.about_us' | translate"
      >
        O nama
      </div>
      <button class="about-us__dropdown-icon" (click)="openAboutUsMenu()">
        <img
          src="/assets/dropdown.svg"
          alt=""
          [@openCloseIcon]="!isAboutUsMenuOpen ? 'closedIcon' : 'openIcon'"
        />
      </button>
      <ul class="about-us__dropdown" *ngIf="isAboutUsMenuOpen">
        <li>
          <a
            routerLink="about-us"
            class="about-us__dropdown-link"
            (click)="closeAboutUsMenu($event)"
            [innerHTML]="'navbar.about_us' | translate"
            >O nama
          </a>
        </li>
        <li>
          <a
            routerLink="photojournalists"
            class="about-us__dropdown-link"
            (click)="closeAboutUsMenu($event)"
            [innerHTML]="'navbar.photojournalists' | translate"
            >Fotoreporteri</a
          >
        </li>
        <li>
          <a
            routerLink="impressum"
            class="about-us__dropdown-link"
            (click)="closeAboutUsMenu($event)"
            [innerHTML]="'navbar.impressum' | translate"
            >Impressum</a
          >
        </li>
        <li>
          routerLink="about-us" fragment="contact-us"
          <a
            href="/about-us#contact-us"
            class="about-us__dropdown-link"
            (click)="closeAboutUsMenu($event)"
            [innerHTML]="'navbar.contact' | translate"
            >Kontakt</a
          >
        </li>
      </ul>
      <div
        *ngIf="isAboutUsMenuOpen || isLanguageModalOpen"
        class="about-us__dropdown-background"
        (click)="closeDropdownMenus($event)"
      ></div>
    </button>

    <div class="header__right-small">
      <app-cart-icon *ngIf="isLoggedIn"></app-cart-icon>
      <div *ngIf="isLoggedIn" class="header__vr"></div>

      <div *ngIf="isLoggedIn; then loggedIn; else loggedOut"></div>

      <ng-template #loggedIn>
        <div class="profile-container" *ngIf="user">
          <img
            src="{{ user.profilePic }}"
            style="width: 31px; height: 31px"
            class=""
            (click)="openModal()"
          />
        </div>
      </ng-template>
      <ng-template #loggedOut>
        <button
          class="header__login"
          routerLink="/login"
          [innerHTML]="'navbar.login' | translate"
        >
          Prijavi se
        </button>
      </ng-template>
    </div> -->
  </div>

  <!-- The Modal -->
  <div
    id="myModal"
    class="modal"
    @fadeModal
    *ngIf="!chooseAction"
    (click)="closeModalWindow()"
  >
    <!-- Modal content -->
    <div class="modal-content" (click)="stopModalPropagation($event)">
      <div class="modal-body">
        <div class="modal-headerr">
          <div class="modal-headerr__main" *ngIf="user">
            <img
              src="{{ user.profilePic }}"
              class=""
              style="width: 31px; height: 31px"
            />
            <div class="name-container">{{ user.name }} {{ user.surname }}</div>
          </div>
          <button class="close-btn" (click)="closeModalWindow()">
            <img src="/assets/close-black-small.svg" alt="" />
          </button>
        </div>
        <div class="modal-rest">
          <div class="btn-container">
            <button
              class="btn-setup txt"
              routerLink="/profile"
              (click)="closeModalWindow()"
              [innerHTML]="'navbar.profile' | translate"
            >
              Postavke profila
            </button>
          </div>
          <div class="btn-container">
            <button
              class="btn-history txt"
              routerLink="/profile/downloads"
              (click)="closeModalWindow()"
              [innerHTML]="'navbar.downloads' | translate"
            >
              Povijest narudžbi
            </button>
          </div>
          <div class="btn-container">
            <button class="btn-logout txt" (click)="logOut()">
              <span id="test" [innerHTML]="'navbar.logout' | translate"
                >Odjava</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<header class="header header-mobile">
  <div class="header-mobile__left">
    <img src="/assets/hamburger.svg" alt="Menu" (click)="openHamburgerMenu()" />
  </div>
  <div class="header-mobile__middle">
    <img
      class="header__logo"
      style="cursor: pointer"
      [routerLink]="[currentLang]"
      src="/assets/logo.svg"
      alt="logo"
    />
  </div>
  <!-- <div class="header-mobile__right">
    <app-cart-icon class="cart-icon-mobile" *ngIf="isLoggedIn"></app-cart-icon>

    <div *ngIf="isLoggedIn; then loggedIn; else loggedOut"></div>

    <ng-template #loggedIn>
      <div class="profile-container" *ngIf="user">
        <img
          src="{{ user.profilePic }}"
          style="width: 31px; height: 31px"
          class=""
          (click)="openModal()"
        />
      </div>
    </ng-template>
    <ng-template #loggedOut>
      <button
        class="header__login"
        routerLink="/login"
        [innerHTML]="'navbar.login' | translate"
      >
        Prijavi se
      </button>
    </ng-template>
  </div> -->
</header>

<div class="navigation-hamburger" *ngIf="isHamburgerMenuOpen">
  <div class="navigation-hamburger__header">
    <div>
      <button
        [class.mobile-currentLang-selected]="currentLang == 'hr'"
        class="mobile-currentLang-button"
        (click)="redirectToChangeLanguage('hr', $event)"
      >
        <img src="/assets/croatia-flag.png" alt="" />
      </button>
      <button
        [class.mobile-currentLang-selected]="currentLang == 'en'"
        class="mobile-currentLang-button"
        (click)="redirectToChangeLanguage('en', $event)"
      >
        <img src="/assets/english-flag.png" alt="" />
      </button>
    </div>
    <button class="close-hamburger" (click)="closeHamburgerMenu()">
      <img src="assets/close-hamburger-modal.svg" alt="" />
    </button>
  </div>
  <hr />
  <nav>
    <ul
      class="header__nav header__nav-mobile"
      *ngIf="!isLoggedIn || (isLoggedIn && isLoaded)"
    >
      <li>
        <a [href]="'/' + currentLang" [innerHTML]="'homepage_services.navbar.index' | translate">
        </a>
      </li>
      <li>
        <a
          [routerLink]="'/' + currentLang + '/services/event'"
          [innerHTML]="'homepage_services.navbar.dropdown.events' | translate"
        >
        </a>
      </li>
      <li>
        <a
          [routerLink]="'/' + currentLang + '/services/portrait'"
          [innerHTML]="'homepage_services.navbar.dropdown.location' | translate"
        >
        </a>
      </li>
      <li>
        <a
          [routerLink]="'/' + currentLang + '/services/product'"
          [innerHTML]="'homepage_services.navbar.dropdown.product' | translate"
        >
        </a>
      </li>
      <li>
        <a
          [routerLink]="'/' + currentLang + '/services/drone'"
          [innerHTML]="'homepage_services.navbar.dropdown.drone' | translate"
        >
        </a>
      </li>
      <li>
        <a
          [routerLink]="'/' + currentLang + '/services/native'"
          [innerHTML]="'homepage_services.navbar.dropdown.native' | translate"
        >
        </a>
      </li>
      <li>
        <a
          [routerLink]="'/' + currentLang + '/services/studio'"
          [innerHTML]="'homepage_services.navbar.dropdown.studio' | translate"
        >
        </a>
      </li>
      <li>
        <a
          [routerLink]="'/' + currentLang + '/about-us'"
          [innerHTML]="'homepage_services.navbar.about' | translate"
        >
        </a>
      </li>
      <li>
        <a
          [routerLink]="'/' + currentLang + '/contact-us'"
          [innerHTML]="'homepage_services.navbar.contact' | translate"
        >
        </a>
      </li>
      <li>
        <a href="https://www.pixsell.hr" target="_blank">pixsell.hr</a>
      </li>

      <!-- TODO - enable AGEPHOTOSTOCK -->
      <!--<li>
        <a
          routerLink="/agephotostock"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
          [class.remembering]="currentSearchSubPortal == 'AGEPHOTOSTOCK'"
          [innerHTML]="'navbar.agephotostock' | translate"
          >AGEPHOTOSTOCK
        </a>
      </li>-->
    </ul>
  </nav>
  <hr />

  <!-- <div class="about-us-mobile-menu">
    <div
      class="about-us-mobile-menu-title"
      [innerHTML]="'navbar.about_us' | translate"
    >
      O nama
    </div>
    <ul class="about-us-mobile-menu-list">
      <li>
        <a routerLink="about-us" [innerHTML]="'navbar.about_us' | translate"
          >O nama</a
        >
      </li>
      <li>
        <a
          routerLink="photojournalists"
          [innerHTML]="'navbar.photojournalists' | translate"
          >Fotoreporteri</a
        >
      </li>
      <li>
        <a routerLink="impressum" [innerHTML]="'navbar.impressum' | translate"
          >Impressum</a
        >
      </li>
      <li>
        <a
          routerLink="about-us"
          fragment="contact-us"
          [innerHTML]="'navbar.contact' | translate"
          >Kontakt</a
        >
      </li>
    </ul>
  </div>
</div> -->

  <div class="modal-content modal-content-mobile" *ngIf="!chooseAction">
    <div class="modal-body">
      <div class="modal-headerr">
        <div class="modal-headerr__main" *ngIf="user">
          <img
            src="{{ user.profilePic }}"
            class=""
            style="width: 31px; height: 31px"
          />
          <div class="name-container">{{ user.name }} {{ user.surname }}</div>
        </div>
        <button class="close-btn" (click)="closeModalWindow()">
          <img src="/assets/close-black-small.svg" alt="" />
        </button>
      </div>
      <div class="modal-rest">
        <div class="btn-container">
          <button
            class="btn-setup txt"
            routerLink="/profile"
            (click)="closeModalWindow()"
            [innerHTML]="'navbar.profile' | translate"
          >
            Postavke profila
          </button>
        </div>
        <div class="btn-container">
          <button
            class="btn-history txt"
            routerLink="/profile/downloads"
            (click)="closeModalWindow()"
            [innerHTML]="'navbar.downloads' | translate"
          >
            Povijest narudžbi
          </button>
        </div>
        <div class="btn-container">
          <button class="btn-logout txt" (click)="logOut()">
            <span id="test" [innerHTML]="'navbar.logout' | translate"
              >Odjava</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
