import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { InfiniteScrollService } from './shared/infinite-scroll.service';
import { SnackBarService } from './share/snack-bar.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ScrollService } from './shared/scroll.service';
import { DraggingService } from './shared/dragging.service';
import { LoadingService } from './shared/loading.service';
import { PermissionsService } from './permissions.service';
import { Subject, Subscription } from 'rxjs';
import { FilterRememberingService } from './front/filter-remembering.service';
import { takeUntil } from 'rxjs/operators';
import {
  ConfirmModal,
  ConfirmModalService,
} from './shared/confirm-modal.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
  EuroPhase,
  RemoteSettingsService,
  Settings,
} from './remote-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('enterLeaveSnackBar', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('200ms ease-out', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(100%)' })),
      ]),
    ]),

    trigger('openClose', [
      transition(':enter', [
        style({ opacity: 0.0 }),
        animate('1s ease-out', style({ opacity: 1.0 })),
      ]),
      transition(':leave', [animate('100ms ease-in', style({ opacity: 0.0 }))]),
    ]),

    trigger('quickOpenClose', [
      transition(':enter', [
        style({ opacity: 0.0 }),
        animate('200ms ease-in', style({ opacity: 1.0 })),
      ]),
      transition(':leave', [
        animate('100ms ease-out', style({ opacity: 0.0 })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();

  public readonly euroPhase: typeof EuroPhase = EuroPhase;

  public snackBar?: { title: string; state: 'INFO' | 'ERROR' };
  public confirmModals: ConfirmModal[] = [];
  public isLoading = false;
  public isAppLoaded = false;

  public settings?: Settings;

  private language: 'hr' | 'en' = 'hr';
  private currentLanguageSubscription = new Subscription();

  @ViewChild('mainAppContainer') mainAppContainerRef: ElementRef | null = null;

  constructor(
    private elementRef: ElementRef,
    private infiniteScrollService: InfiniteScrollService,
    private scrollService: ScrollService,
    private snackBarService: SnackBarService,
    private draggingService: DraggingService,
    private loadingService: LoadingService,
    private permissionsService: PermissionsService,
    private filterRememberingService: FilterRememberingService,
    private confirmModalService: ConfirmModalService,
    private translate: TranslateService,
    private remoteSettingsService: RemoteSettingsService
  ) {}

  ngOnInit(): void {
    this.remoteSettingsService.loadSettings();
    this.remoteSettingsService.settingsObservable.subscribe((settings) => {
      this.settings = settings;
      this.isAppLoaded = true;
    });

    // check local storage
    this.language = localStorage.getItem('language') as any;
    // turn into a function in the future
    switch (this.language) {
      case 'hr':
      case 'en':
        break;
      default:
        this.language = 'hr';
        break;
    }

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('hr');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.language);

    // update local storage when language changes
    this.currentLanguageSubscription = this.translate.onLangChange.subscribe(
      (params: LangChangeEvent) => {
        localStorage.setItem('language', params.lang);
        this.language = params.lang as any;
      }
    );

    this.snackBarService.snackBarData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.snackBar = data;
      });

    this.draggingService.draggingData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isDragging) => {
        this.setIsDragging(isDragging);
      });

    this.loadingService.loadingData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });

    this.filterRememberingService.setScrollPositionData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value.isAppComponent) {
          this.mainAppContainerRef!.nativeElement!.scrollTo(0, value.position);
        }
      });

    this.confirmModalService.confirmModalData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((confirmModal) => {
        this.confirmModals.push(confirmModal);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.currentLanguageSubscription.unsubscribe();
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // DRAGGING
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  setIsDragging(isDragging: boolean) {
    const htmlElement = this.elementRef.nativeElement.closest('html');

    if (isDragging) {
      htmlElement.classList.add('dragging');
    } else {
      htmlElement.classList.remove('dragging');
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // MODAL
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  modalConfirm(modal: ConfirmModal) {
    modal.onConfirm();
    this.confirmModals = this.confirmModals.filter((cM) => cM !== modal);
  }

  modalClose(modal: ConfirmModal) {
    this.confirmModals = this.confirmModals.filter((cM) => cM !== modal);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // SNACK BAR
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  closeSnackBar() {
    this.snackBar = undefined;
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // SCROLL
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onNormalScroll(event: any) {
    const currentScrollPosition = event.target.scrollTop;
    this.filterRememberingService.rememberScrollPosition(
      currentScrollPosition,
      true
    );
    this.scrollService.signalScroll(currentScrollPosition);
  }

  onScroll() {
    // only needed for mobile rn, so this check is here to make sure something unintended doesn't happen even though it should probably be fine
    const width: number =
      document.querySelector('.main-app-container')?.clientWidth ?? 0;
    if (width <= 1024) {
      // isMobile
      this.infiniteScrollService.signalScroll();
    }
  }
}
